import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

const StyledMultiList = styled(MultiList)(() => ({
  textTransform: 'uppercase',
}))
 
export const PROJECT_REACTIVE_ID = 'ProjectFilter';
 
interface ProjectFilterProps {
  fixedValue?: string;
  react?: React.ReactNode;
}
 
interface ProjectFilterState {
  sortAlphabetically: boolean;
  anchorEl: null | HTMLElement;
  isVisible: boolean;
  selectedItems: string[];
}
 
@observer
export default class ProjectFilter extends React.Component<ProjectFilterProps, ProjectFilterState> {
  @observable sortAlphabetically: boolean = false;
  @observable anchorEl: null | HTMLElement = null;
  @observable isVisible: boolean = true;
  @observable selectedItems: string[] = [];

  constructor(props: ProjectFilterProps) {
    super(props);
    makeObservable(this);
  }
  
  @action
  handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    this.anchorEl = event.currentTarget;
  }
  
  @action
  handleMenuClose() {
    this.anchorEl = null;
  }
 
  @action
  handleSort(sortAlphabetically: boolean) {
    this.sortAlphabetically = sortAlphabetically;
    this.handleMenuClose();
  }
 
  @action
  handleSelectionChange(selectedValues: string[]) {
    this.selectedItems = selectedValues;
  }

  @computed
  get shouldDisplay() {
    return !this.props.fixedValue ? {} : { display: 'none' };
  }
 
  @computed
  get sortedItems() {
    const { sortAlphabetically } = this;
    return (items) => {
      if (sortAlphabetically) {
        return items.sort((a, b) => a.key.localeCompare(b.key));
      } else {
        return items.sort((a, b) => b.doc_count - a.doc_count);
      }
    };
  }

  render() {
    const { fixedValue } = this.props;
    const { sortAlphabetically, anchorEl, isVisible, selectedItems } = this;

    return (
      <div style={this.shouldDisplay}>
        {isVisible && (
          <StyledMultiList
            className="data-filter"
            componentId={PROJECT_REACTIVE_ID}
            dataField="project_name.keyword"
            title={
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{ textTransform: 'capitalize' }}>Project</span>
                <FilterAltIcon onClick={(e) => this.handleMenuOpen(e)} style={{ cursor: 'pointer' }} />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => this.handleMenuClose()}
                >
                  <MenuItem onClick={() => this.handleSort(false)}>Sort by Count</MenuItem>
                  <MenuItem onClick={() => this.handleSort(true)}>Sort Alphabetically</MenuItem>
                </Menu>
              </div>
            }
            filterLabel="Project"
            showSearch={false}
            react={this.props.react}
            URLParams={!Boolean(fixedValue)}
            showFilter={!Boolean(fixedValue)}
            sortBy={sortAlphabetically ? 'asc' : 'count'}
            onChange={(values) => this.handleSelectionChange(values)}
            value={selectedItems}
            transformData={this.sortedItems}
          />
        )}
      </div>
    );
  };
}
