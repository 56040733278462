import { Component } from 'react';
import {
  faMapMarkedAlt,
  faList,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import difference from 'lodash/difference';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';

import { styled } from '@mui/material/styles';
import { Button, Alert } from '@mui/material';

import theme from '@extensions/services/Theme';
import { ICachingService } from '@extensions/services/ICachingService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import HeadManager from './HeadManager';

import {
  getHashValues,
  setHashValues,
  stringify,
  parse,
} from '@dapclient/utils/url';
import {
  SearchBar,
  SEARCH_BAR_REACTIVE_ID,
} from '@dapclient/components/search-core';
import AddIcon from '@extensions/utils/AddIcon';
import {
  RecommendedFilter,
  DataLevelFilter,
  InstrumentFilter,
  MeasurementFilter,
  ModelFilter,
  StandardizationFilter,
  FileTypeFilter,
  DateRangeFilter,
  ProjectFilter,
  AreaFilter,
  ResultsList,
  RECOMMENDED_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import ITokenService from '@extensions/services/ITokenService';
import { SelectedFilters } from '@extensions/components/search-core';

const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  RECOMMENDED_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
];

export interface ISearchProps {
  projectContext?: string
  cachingService?: ICachingService;
  securityService?: ISecurityService;
  tokenService?: ITokenService;
  className?: string;
}

const StyledFiltersAndMapToggleDiv = styled('div')(({
  display: 'flex',
  marginTop: "-1rem",
  marginBottom: "0.5rem",
  alignItems: 'flex-end',
  justifyContent: 'space-between'
}));

const StyledSelectedFilters = styled(SelectedFilters)(({
  flex: '1',
  marginRight: '0.5rem',
}));

const StyledMapToggleButton = styled(Button)(({
  flex: '0 0 auto',
  flexDirection: 'row-reverse'
}));

const StyledMapToggleButtonTextSpan = styled('span')(({
  marginLeft: '0.25rem',
}));

const StyledBodyDiv = styled('div')(({
  display: 'flex',
}));

const StyledSection = styled('section')(({
  width: '300px',
  flex: '0 0 auto',
  marginRight: '1rem',
  '& .data-filter': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledResultsDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
}));

const StyledAlertMessageSpan = styled('span')(({
  color: '#000'
}));

@inject('cachingService', 'securityService', 'tokenService')
@observer
class DatasetSearch extends Component<ISearchProps> {
  @observable
  showMap: boolean = true;
  @observable
  showMapToggleButton: boolean = false;
  @observable
  defaultViz: boolean | undefined;

  @action
  toggleMap = () => (this.showMap = !this.showMap);

  @action
  onInitialMapLoad = () => (this.showMapToggleButton = true);

  allReactiveIdsExcept = (excluded) => difference(REACTIVE_IDS, [excluded]);

  constructor(props: ISearchProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { projectContext, cachingService } = this.props;
    if (!projectContext) {
      this.defaultViz = false;
      return;
    }
    if (projectContext && cachingService) {
      cachingService.getProjectLite(projectContext).then(project => {
        this.defaultViz = project.hasTimeSeries ?? false;
      });
    }
  }

  render() {
    const {
      projectContext,
      securityService,
      tokenService,
    } = this.props;
    if (!tokenService || !securityService) {
      return null;
    }

    return (
      <div>
        <HeadManager
          title="A2e: Atmosphere to Electrons Datasets"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
        {securityService.user &&
          securityService.user.authenticated &&
          securityService.user.mfaEnabled &&
          !securityService.user.authenticatedMfa && (
            <Alert
              severity="warning"
              icon={<AddIcon icon={faInfoCircle} />}
              sx={{
                ...theme.MuiAlert.outlinedWarning
              }}
            >
              <StyledAlertMessageSpan>
                You have signed in without your second-factor token. You will
                NOT see any proprietary datasets.
              </StyledAlertMessageSpan>
            </Alert>
          )}
        <ReactiveBase
          app="api/datasets"
          url={`${window.location.protocol}//${window.location.host}`}
          headers={{ 'x-csrf-token': tokenService.dapToken }}
          getSearchParams={() => {
            // Uses hash instead of query string and allows for non-native (to
            // Reactivesearch) pieces to be in the URL
            let params: { [key: string]: string } = {};
            const hash = getHashValues();
            for (const key in hash) {
              if (REACTIVE_IDS.indexOf(key) < 0) {
                continue;
              }
              params[key] = hash[key] as string;
            }
            return stringify(params);
          }}
          setSearchParams={(url) => {
            // Uses hash instead of query string and allows for non-native (to
            // Reactivesearch) pieces to be in the URL
            const params = parse(url.split('?')[1]) as {
              [key: string]: string;
            };
            const hash = getHashValues();
            let keep: { [key: string]: string } = {};
            for (const key in params) {
              if (params[key]) {
                keep[key] = params[key] as string;
              }
            }
            for (const key in hash) {
              if (REACTIVE_IDS.indexOf(key) < 0) {
                keep[key] = hash[key] as string;
              }
            }
            setHashValues(keep);
          }}
          transformRequest={req => {
            req.body = req.body.replace(
              '"sort":[{"dapFileSummary.updated":{"order":"desc"}}]',
              '"sort":' + JSON.stringify([
                { "dapFileSummary.updated": { missing: "_last", order: "desc" } },
                { generalUseReady: { order: "desc" } },
              ])
            );
            return req;
          }}
        >
          <StateProvider
            componentIds={[PROJECT_REACTIVE_ID, DATE_RANGE_REACTIVE_ID]}
            includeKeys={['value']}
            onChange={(prev, next) => {
              // This is to hack around this issue:
              // https://github.com/appbaseio/reactivesearch/issues/1460
              // ...only affects the project filter and date range filter
              const value = next[PROJECT_REACTIVE_ID].value;
              if (value === undefined || value === null) {
                let hash = getHashValues();
                if (PROJECT_REACTIVE_ID in hash) {
                  delete hash[PROJECT_REACTIVE_ID];
                }
                if (DATE_RANGE_REACTIVE_ID in hash) {
                  delete hash[DATE_RANGE_REACTIVE_ID];
                }
                setHashValues(hash);
              }
              return null;
            }}
          />
          <div role="search" style={{ paddingBottom: '8px' }}>
            <SearchBar
              dataField={[
                '*',
                // Do we need to call these out individually?
                // 'identifier',
                // 'project_name',
                // 'description',
                // 'dataQualityDescription',
                // 'uncertaintyDescription',
                // 'constraintsDescription',
                // 'instrument',
                // 'instrument.keyword',
                // 'dataLevel',
                // 'dataLevel.keyword',
                // 'instance',
                // 'instance.keyword',
                // 'title',
                // 'doiName',
                // 'contactPoint.fn',
                // 'contactPoint.hasEmail',
                // 'contactPoint.hasOrg',
                // 'measurements.description',
                // 'measurements.standardName.description',
              ]}
              filterLabel="Search"
              queryFormat="and"
              queryString={true}
              URLParams={true}
              debounce={200}
              autosuggest={false}
            />
          </div>
          <StyledFiltersAndMapToggleDiv>
            <StyledSelectedFilters />
            <StyledMapToggleButton
              onClick={this.toggleMap}
              aria-hidden
            >
              <>
                <AddIcon
                  icon={this.showMap ? faList : faMapMarkedAlt}
                />
                <StyledMapToggleButtonTextSpan>
                  {this.showMap ? 'Hide Map' : 'Show Map'}
                </StyledMapToggleButtonTextSpan>
              </>
            </StyledMapToggleButton>
          </StyledFiltersAndMapToggleDiv>
          <StyledBodyDiv>
            <StyledSection aria-label="search filters" >
              <ProjectFilter
                fixedValue={projectContext}
                react={{
                  and: this.allReactiveIdsExcept(PROJECT_REACTIVE_ID),
                }}
              />
              <DateRangeFilter
                react={{
                  and: this.allReactiveIdsExcept(DATE_RANGE_REACTIVE_ID),
                }}
                startDateField="dapFileSummary.begins"
                endDateField="dapFileSummary.ends"
              />
              <RecommendedFilter
                react={{
                  and: this.allReactiveIdsExcept(RECOMMENDED_REACTIVE_ID),
                }}
              />
              <DataLevelFilter
                react={{
                  and: this.allReactiveIdsExcept(DATA_LEVEL_REACTIVE_ID),
                }}
              />
              <InstrumentFilter
                react={{
                  and: this.allReactiveIdsExcept(INSTRUMENT_REACTIVE_ID),
                }}
              />
              <MeasurementFilter
                react={{
                  and: this.allReactiveIdsExcept(MEASUREMENT_REACTIVE_ID),
                }}
              />
              <ModelFilter
                react={{
                  and: this.allReactiveIdsExcept(MODEL_REACTIVE_ID),
                }}
              />
              <StandardizationFilter
                react={{
                  and: this.allReactiveIdsExcept(STANDARDIZATION_REACTIVE_ID),
                }}
              />
              <FileTypeFilter
                react={{
                  and: this.allReactiveIdsExcept(FILE_TYPE_REACTIVE_ID),
                }}
              />
            </StyledSection>
            <StyledResultsDiv>
              <AreaFilter
                showMap={this.showMap}
                onInitialMapLoad={this.onInitialMapLoad}
                react={{
                  and: this.allReactiveIdsExcept(AREA_FILTER_REACTIVE_ID),
                }}
              />
              {this.defaultViz !== undefined && (
                <ResultsList
                  react={{
                    and: REACTIVE_IDS,
                  }}
                  pageSize={20}
                  vizByDefault={this.defaultViz}
                />
              )}
            </StyledResultsDiv>
          </StyledBodyDiv>
        </ReactiveBase>
      </div>
    );
  }
}

export default DatasetSearch;
